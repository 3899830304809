<script>
import ProductCard from "../../ProductCard.vue";

export default {
    name: 'RecommendProducts',
    components: {
        ProductCard
    },
    props: {
        data: Object,
    },
    computed: {
        listProducts() {
            return this.data.listRecommend.filter((item) => item.name);
        },
    },
    data() {
        return {
            products: this.data,
        };
    },
};
</script>

<template>
    <section class="product-section" v-if="products.listRecommend && listProducts.length > 0">
        <div class="recommend-products-title">
            <h2 class="section-title-menu">
                {{ products.title }}
            </h2>
            <p class="sub-title-menu">
                {{ products.subtitle }}
            </p>
        </div>
        <div class="product-grid">
            <a :href="'san-pham/' + encodeURIComponent(item.slug)" v-for="item in listProducts" :key="item.id">
                <ProductCard :product="item" v-if="item.name" />
            </a>
        </div>
        <a class="view-more-button" v-if="products.buttonText && products.buttonUrl" :href="products.buttonUrl">
            {{ products.buttonText }}
        </a>
    </section>
</template>
