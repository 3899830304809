<template>
    <section class="landscape-products-wrap" v-if="hasAnyProduct">
        <div class="container">
            <h1 class="products-header">
                {{ $trans("product::attributes.related_products") }}
            </h1>
            <section class="related-products">
                <VueSlickCarousel v-bind="sliderSettings">
                    <a :href="'san-pham/' + encodeURIComponent(item.slug)" v-for="item in listProducts" :key="item.id">
                        <ProductCard :product="item" />
                    </a>
                </VueSlickCarousel>
            </section>
        </div>
    </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import RelatedProductCard from "./../../RelatedProductCard.vue";
import ProductCard from "../../ProductCard.vue";

export default {
    components: {
        RelatedProductCard,
        VueSlickCarousel,
        ProductCard
    },

    props: ["products"],

    computed: {
        listProducts() {
            return this.products.filter((product) => product.name);
        },
        hasAnyProduct() {
            return this.listProducts.length !== 0;
        },
    },

    data() {
        return {
            sliderSettings: {
                arrows: true,
                dots: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                dotsClass: "slick-dots dots-vision",
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            dots: true,
                            arrows: false,
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: true,
                            arrows: false,
                        },
                    },
                ],
            },
        };
    },
};
</script>

