<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "VisionMissionValues",
    components: {
        VueSlickCarousel,
    },
    props: {
        qualityIcon: {
            type: String,
            default: "build/assets/quality.svg",
        },
        learnIcon: {
            type: String,
            default: "build/assets/learn.svg",
        },
        hearIcon: {
            type: String,
            default: "build/assets/hear.svg",
        },
        creativeIcon: {
            type: String,
            default: "build/assets/creative.svg",
        },
        defaultImage: {
            type: String,
            default: "build/assets/showcase_default.png"
        },
        data: String
    },
    data() {
        return {
            sliderSettings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplaySpeed: 3000,
                dotsClass: "slick-dots custom-dots",
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                        },
                    },
                ],
            },
            sliders: [],
        };
    },
    beforeMount() {
        this.vision = JSON.parse(this.data);
    },
};
</script>

<template>
    <div class="vision-mission-values">
        <div class="vision-mission-values__container">
            <div class="vision-mission-values__content">
                <div class="vision-mission-values__text">
                    <div class="vision-mission-values__heading">
                        <h2 class="vision-mission-values__title">
                            {{ vision.title }}
                        </h2>
                        <div class="vision-mission-values__body">
                            <strong>{{ vision.title_vision }}</strong>
                            <span>{{ vision.content_vision }}</span>
                        </div>
                        <div class="vision-mission-values__body">
                            <strong>{{ vision.title_mission }}</strong>
                            <span>{{ vision.content_mission }}</span>
                        </div>
                        <div class="vision-mission-values__core-values">
                            <div class="vision-mission-values__core-values-row">
                                <div class="vision-mission-values__core-value">
                                    <img
                                        :src="qualityIcon"
                                        alt="quality"
                                        class="vision-mission-values__core-value-icon"
                                    />
                                    <h3 class="vision-mission-values__core-value-title">
                                        {{ vision.title_quality }}
                                    </h3>
                                    <p class="vision-mission-values__core-value-description">
                                        {{ vision.content_quality }}
                                    </p>
                                </div>
                                <div class="vision-mission-values__core-value">
                                    <img
                                        :src="learnIcon"
                                        alt="learning"
                                        class="vision-mission-values__core-value-icon"
                                    />
                                    <h3 class="vision-mission-values__core-value-title">
                                        {{ vision.title_learning }}
                                    </h3>
                                    <p class="vision-mission-values__core-value-description">
                                        {{ vision.content_learning }}
                                    </p>
                                </div>
                            </div>
                            <div class="vision-mission-values__core-values-row">
                                <div class="vision-mission-values__core-value">
                                    <img
                                        :src="hearIcon"
                                        alt="hearing"
                                        class="vision-mission-values__core-value-icon"
                                    />
                                    <h3 class="vision-mission-values__core-value-title">
                                        {{ vision.title_hearing }}
                                    </h3>
                                    <p class="vision-mission-values__core-value-description">
                                        {{ vision.content_hearing }}
                                    </p>
                                </div>
                                <div class="vision-mission-values__core-value">
                                    <img
                                        :src="creativeIcon"
                                        alt="creative"
                                        class="vision-mission-values__core-value-icon"
                                    />
                                    <h3 class="vision-mission-values__core-value-title">
                                        {{ vision.title_creative }}
                                    </h3>
                                    <p class="vision-mission-values__core-value-description">
                                        {{ vision.content_creative }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vision-mission-values__image">
                    <VueSlickCarousel v-bind="sliderSettings">
                        <div v-for="item in vision.sliderVision" :key="item.id">
                            <img
                                :src="item.image.path ?? defaultImage"
                                :alt="item.name"
                                class="vision-mission-values__img"
                            />
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
    </div>
</template>
