<template>
    <div class="proprietary-product">
        <div class="proprietary-product-title">
            <h2 class="section-title-menu">
                {{ sectionData.storefront_product_exclusive_title }}
            </h2>
            <p class="sub-title-menu">
                {{ sectionData.storefront_product_exclusive_subtitle }}
            </p>
        </div>
        <div class="proprietary-product-content" :style="backgoundImage">
            <div class="content">
                <p>
                    {{ sectionData.storefront_product_exclusive_content }}
                </p>
                <a class="view-more-button" :href="sectionData.buttonUrl" v-if="sectionData.buttonText && sectionData.buttonUrl">
                    {{ sectionData.buttonText }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "ProprietaryProduct",
    props: {
        data: Object,
    },
    computed: {
        backgoundImage() {
            if (this.sectionData.storefront_product_exclusive_banner) {
                return {
                    backgroundImage: `url(${this.sectionData.storefront_product_exclusive_banner})`,
                };
            }
            return {};
        },
    },
    data() {
        return {
            sectionData: this.data,
        };
    },
}
</script>
