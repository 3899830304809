<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductCard from "../../ProductCard.vue";

export default {
    name: 'FeaturedProducts',
    components: {
        VueSlickCarousel,
        ProductCard,
    },
    props: {
        data: String,
    },
    computed: {
        listProducts() {
            return JSON.parse(this.data).listFeaturedProducts.filter((item) => item.name);
        },
    },
    data() {
        return {
            sliderSettings: {
                arrows: false,
                dots: true,
                slidesToShow: 4,
                slidesToScroll: 2,
                dotsClass: "slick-dots dots-vision",
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 577,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: true,
                        },
                    },
                ],
            },
            products: JSON.parse(this.data),
        };
    },
};
</script>

<template>
    <section class="featured-products">
        <div class="featured-products-title">
            <h2 class="section-title-menu">{{ products.title }}</h2>
        </div>
        <section class="featured-products-content">
            <div class="product-grid" v-if="listProducts.length <= 4">
                <a :href="'san-pham/' + encodeURIComponent(item.slug)" v-for="item in listProducts" :key="item.id">
                    <ProductCard :product="item" v-if="item.name" />
                </a>
            </div>
            <VueSlickCarousel v-bind="sliderSettings" v-else>
                <a :href="'san-pham/' + encodeURIComponent(item.slug)" v-for="item in listProducts" :key="item.id">
                    <ProductCard :product="item"/>
                </a>
            </VueSlickCarousel>
        </section>
    </section>
</template>
