<template>
    <div class="news-event" :style="displayNone">
        <div class="news-event-title">
            <h2 class="section-title-menu">
                {{ sectionData.title }}
            </h2>
            <p class="sub-title-menu">
                {{ sectionData.subtitle }}
            </p>
        </div>
        <div class="news-event-grid">
            <a :href="'bai-viet/' + item.slug" class="news-card" v-for="item in listFeaturedBlogs" :key="item.id" v-show="item.slug">
                <div class="news-image">
                    <img :src="item.image_path" :alt="item.slug" :title="item.name">
                </div>
                <div class="news-content">
                    <p class="date">{{ item.created_at_formatted }}</p>
                    <h3 class="title">{{ item.name }}</h3>
                </div>
                <p>{{ $trans('storefront::layout.view_more') }} <i class="las la-angle-right"></i></p>
            </a>
        </div>
        <div class="news-event-slide">
            <VueSlickCarousel v-bind="sliderSettings" v-if="countNewsEvent >= 1">
                <a :href="'bai-viet/' + item.slug" class="news-card" v-for="item in listFeaturedBlogs" :key="item.id">
                    <div class="news-image">
                        <img :src="item.image_path" :alt="item.slug" :title="item.name">
                    </div>
                    <div class="news-content">
                        <p class="date">{{ item.created_at_formatted }}</p>
                        <h3 class="title">{{ item.name }}</h3>
                    </div>
                    <p>{{ $trans('storefront::layout.view_more') }} <i class="las la-angle-right"></i></p>
                </a>
            </VueSlickCarousel>
        </div>
        <a class="view-more-button" :href="sectionData.buttonUrl"
            v-if="sectionData.buttonText && sectionData.buttonUrl">
            {{ sectionData.buttonText }}
        </a>
    </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    name: "NewsEvent",
    props: {
        data: Object,
    },
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            sliderSettings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                padding: 10,
                gap: 10,
                dotsClass: "slick-dots dots-vision",
            },
            sectionData: this.data,
        };
    },
    computed: {
        countNewsEvent() {
            return this.sectionData.listFeaturedBlogs.filter((item) => item.name).length;
        },
        listFeaturedBlogs() {
            return this.sectionData.listFeaturedBlogs.filter((item) => item.name);
        },
        displayNone() {
            return this.countNewsEvent === 0 ? { display: 'none' } : {};
        },
    },
}
</script>
