<template>
    <div class="header-search-wrap" v-click-outside="hideSuggestions">
        <div class="header-search">
            <form class="search-form" @submit.prevent="search">
                <div class="header-search-lg">
                    <input type="text" name="query" class="form-control search-input" autocomplete="off"
                        v-model="form.query" :placeholder="$trans('storefront::layout.search_for_products')"
                        @focus="showExistingSuggestions" @keydown.esc="hideSuggestions" @keydown.down="nextSuggestion"
                        @keydown.up="prevSuggestion" />
                    <div class="select-wrap">
                        <div class="category-select" @click="toggleSelect()">
                            <p v-text="selectCategory"></p>
                            <div class="custom-select-dropdown" :style="showSelectDropdown">
                                <div v-for="category in allCategories" :key="category.slug" class="select-option"
                                    @click="changeCategory(category)">
                                    {{ category.name }}
                                </div>
                            </div>
                            <i class="las la-angle-down"></i>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn-search">
                    <i class="las la-search"></i>
                </button>
            </form>

            <div v-if="
                    Boolean(isMostSearchedKeywordsEnabled) &&
                    mostSearchedKeywords.length !== 0
                " class="searched-keywords">
                <label>
                    {{ $trans("storefront::layout.most_searched") }}
                </label>

                <ul class="list-inline searched-keywords-list">
                    <li v-for="(
                            mostSearchedKeyword, index
                        ) in mostSearchedKeywords" :key="index">
                        <a :href="
                                route('products.index', {
                                    query: mostSearchedKeyword,
                                })
                            ">
                            {{ mostSearchedKeyword }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="header-search-sm-form">
            <div class="over-lay-header"></div>
            <div class="search-form-header">
                <div class="btn-close" @click="showSuggestions = false">
                    <i class="las la-times"></i>
                </div>
            </div>
            <form class="search-form" @submit.prevent="search">
                <!-- Cannot use v-model due to a bug. See https://github.com/vuejs/vue/issues/8231 -->
                <input type="text" name="query" class="form-control search-input-sm" autocomplete="off" :placeholder="
                        $trans('storefront::layout.search_for_products')
                    " :value="form.query" @input="form.query = $event.target.value" @focus="showExistingSuggestions"
                    @keydown.esc="hideSuggestions" @keydown.down="nextSuggestion" @keydown.up="prevSuggestion" />

                <button type="submit" class="btn-search">
                    <i class="las la-search"></i>
                </button>
            </form>
        </div>

        <div class="header-search-sm">
            <i class="las la-search"></i>
            <span @click="showSuggestions = true">
                {{ $trans("storefront::layout.search_for_products") }}
            </span>
        </div>

        <div class="search-suggestions overflow-hidden" v-if="shouldShowSuggestions">
            <div class="search-suggestions-inner custom-scrollbar" ref="searchSuggestionsInner">

                <div class="product-suggestion" v-if="suggestions.products.length !== 0">
                    <h6 class="title">
                        {{ $trans("storefront::layout.product_suggestions") }}
                    </h6>

                    <ul class="list-inline product-suggestion-list">
                        <li v-for="product in suggestions.products" :key="product.slug" class="list-item"
                            :class="{ active: isActiveSuggestion(product) }" :ref="product.slug"
                            @mouseover="changeActiveSuggestion(product)" @mouseleave="clearActiveSuggestion">
                            <a :href="product.url" class="single-item" @click="hideSuggestions">
                                <div class="product-image">
                                    <img :src="baseImage(product)" :class="{
                                            'image-placeholder':
                                                !hasBaseImage(product),
                                        }" :alt="product.name" />
                                </div>

                                <div class="product-info">
                                    <div class="product-info-top">
                                        <h6 class="product-name" v-html="product.name"></h6>
                                    </div>

                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <a v-if="suggestions.remaining !== 0" :href="moreResultsUrl" @click="hideSuggestions" class="more-results">
                {{
                $trans("storefront::layout.more_results", {
                count: this.suggestions.remaining,
                })
                }}
            </a>
        </div>
    </div>
</template>

<script>
import { throttle } from "lodash";

export default {
    props: [
        "categories",
        "mostSearchedKeywords",
        "isMostSearchedKeywordsEnabled",
        "initialQuery",
        "initialCategory",
    ],

    data() {
        return {
            activeSuggestion: null,
            showSuggestions: false,
            showSelectDropdown: {
                opacity: 0,
                visibility: 'hidden',
            },
            form: {
                query: this.initialQuery,
                category: this.initialCategory,
            },
            suggestions: {
                categories: [],
                products: [],
                remaining: 0,
            },
        };
    },

    computed: {
        allCategories() {
            return [
                {
                    name: this.$trans("storefront::layout.all_categories"),
                    slug: "",
                },
                ...this.categories.filter((category) => category.is_searchable ),
            ];
        },

        selectCategory: {
            get() {
                if (this.form.category) {
                    const category = this.allCategories.find((category) => category.slug === this.form.category);
                    if (category) {
                        return category.name;
                    }
                    this.form.category = '';
                    return this.$trans("storefront::layout.all_categories");
                }

                return this.$trans("storefront::layout.all_categories");
            },
            set(value) {
                const category = this.categories.find((category) => category.name === value);
                this.form.category = category ? category.slug : null;
            }
        },

        initialCategoryIsNotInCategoryList() {
            return !this.categories.includes(this.initialCategory);
        },

        shouldShowSuggestions() {
            if (!this.showSuggestions) {
                return false;
            }

            return this.hasAnySuggestion;
        },

        moreResultsUrl() {
            if (this.form.category) {
                return route("categories.products.index", this.form);
            }

            return route("products.index", { query: this.form.query });
        },

        hasAnySuggestion() {
            return this.suggestions.products.length !== 0 || this.suggestions.categories.length !== 0;
        },

        allSuggestions() {
            return [
                ...this.suggestions.categories,
                ...this.suggestions.products,
            ];
        },

        firstSuggestion() {
            return this.allSuggestions[0];
        },

        lastSuggestion() {
            return this.allSuggestions[this.allSuggestions.length - 1];
        },
    },

    watch: {
        "form.query": throttle(function (newQuery) {
            if (newQuery === "") {
                this.clearSuggestions();
                this.showSuggestions = false;
            } else {
                this.showSuggestions = true;
                this.fetchSuggestions();
            }
        }, 2000),
    },

    methods: {
        toggleSelect() {
            this.showSelectDropdown = {
                opacity: this.showSelectDropdown.opacity === 0 ? 1 : 0,
                visibility: this.showSelectDropdown.opacity === 0 ? 'visible' : 'hidden',
            };
        },

        changeCategory(category) {
            this.form.category = category.slug;

            this.fetchSuggestions();
        },

        fetchSuggestions() {
            axios
                .get(route("suggestions.index", this.form))
                .then(({data}) => {
                    // this.suggestions.categories = data.categories;
                    this.suggestions.products = data.products;
                    this.suggestions.remaining = data.remaining;

                    this.clearActiveSuggestion();
                    this.resetSuggestionScrollBar();
                });
        },

        search() {
            // if (!this.form.query) {
            //     return;
            // }

            if (this.activeSuggestion) {
                window.location.href = this.activeSuggestion.url;

                this.hideSuggestions();

                return;
            }

            if (this.form.category) {
                window.location.href = route(
                    "categories.products.index",
                    {
                        category: this.form.category,
                        query: this.form.query || null,
                    }
                );

                return;
            }

            window.location.href = route("products.index", {
                query: this.form.query || null,
            });
        },

        showExistingSuggestions() {
            if (this.form.query !== "") {
                this.showSuggestions = true;
            }
        },

        clearSuggestions() {
            this.suggestions.categories = [];
            this.suggestions.products = [];
        },

        hideSuggestions() {
            this.showSuggestions = false;

            this.clearActiveSuggestion();
        },

        isActiveSuggestion(suggestion) {
            if (!this.activeSuggestion) {
                return false;
            }

            return this.activeSuggestion.slug === suggestion.slug;
        },

        changeActiveSuggestion(suggestion) {
            this.activeSuggestion = suggestion;
        },

        clearActiveSuggestion() {
            this.activeSuggestion = null;
        },

        nextSuggestion() {
            if (!this.hasAnySuggestion) {
                return;
            }

            this.activeSuggestion =
                this.allSuggestions[this.nextSuggestionIndex()];

            if (!this.activeSuggestion) {
                this.activeSuggestion = this.firstSuggestion;
            }

            this.adjustSuggestionScrollBar();
        },

        prevSuggestion() {
            if (!this.hasAnySuggestion) {
                return;
            }

            if (this.prevSuggestionIndex() === -1) {
                this.clearActiveSuggestion();

                return;
            }

            this.activeSuggestion =
                this.allSuggestions[this.prevSuggestionIndex()];

            if (!this.activeSuggestion) {
                this.activeSuggestion = this.lastSuggestion;
            }

            this.adjustSuggestionScrollBar();
        },

        nextSuggestionIndex() {
            return this.currentSuggestionIndex() + 1;
        },

        prevSuggestionIndex() {
            return this.currentSuggestionIndex() - 1;
        },

        currentSuggestionIndex() {
            return this.allSuggestions.indexOf(this.activeSuggestion);
        },

        adjustSuggestionScrollBar() {
            this.$refs.searchSuggestionsInner.scrollTop =
                this.$refs[this.activeSuggestion.slug][0].offsetTop - 200;
        },

        resetSuggestionScrollBar() {
            if (this.$refs.searchSuggestionsInner !== undefined) {
                this.$refs.searchSuggestionsInner.scrollTop = 0;
            }
        },

        productUrl(product) {
            return route("products.show", urlencode(product.slug));
        },

        hasBaseImage(product) {
            return product.base_image.length !== 0;
        },

        baseImage(product) {
            if (this.hasBaseImage(product)) {
                return product.base_image.path;
            }

            return `${window.FleetCart.baseUrl}/build/assets/image-placeholder.png`;
        },
    },
    mounted() {
        $(document).on('click', (e) => {
            if (!$(e.target).closest('.category-select').length && !$(e.target).closest('.custom-select-dropdown').length) {
                this.showSelectDropdown = {
                    opacity: 0,
                    visibility: 'hidden',
                };
            }
        });
    },
    // beforeDestroy() {
    //     $(document).off('click');
    // },
};
</script>
