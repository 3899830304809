<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "TipSection",
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/tip_default.png",
        },
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                        },
                    },
                ],
            },
            tips: [],
        };
    },
    methods: {
        prevSlide() {
            this.$refs.slick.prev();
        },
        nextSlide() {
            this.$refs.slick.next();
        },
    },
    beforeMount() {
        this.tips = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="tips-section">
        <div class="tips-container">
            <div class="tips-content">
                <div class="tips-column">
                    <div class="tips-header">
                        <h2 class="section-title-menu">{{ tips.title }}</h2>
                        <p class="tips-description">
                            {{ tips.description }}
                        </p>
                        <a href="/" class="view-more-button"> {{ tips.button}}</a>
                    </div>
                </div>
                <div class="tips-column-articles">
                    <VueSlickCarousel ref="slick" v-bind="slickOptions">
                        <a :href="'blog/' + item.slug" v-for="item in tips.blogs" :key="item.id">
                            <div class="tips-article">
                                <img
                                    :src="item.image_path ?? defaultImage"
                                    :alt="item.name"
                                    class="tips-article-image"
                                />
                                <h3 class="tips-article-title">
                                    {{ item.name }}
                                </h3>
                                <p class="tips-article-excerpt" v-html="item.description">
                                </p>
                            </div>
                        </a>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
        <div class="tips-pagination">
            <div class="tips-pagination-controls">
                <button class="tips-pagination-prev" @click="prevSlide">
                    <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/96237b0e42c457b16e767ecec86053ef9d2b2a1871079d6f951740b84e02b99d?apiKey=f4720969da584a5080659c2fbf41bfab&"
                        alt="Previous"
                        class="tips-pagination-icon"
                    />
                </button>
                <button class="tips-pagination-next" @click="nextSlide">
                    <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/43d0cf80b0f1326bf7a71fe72cc885b6e65b31e6a733df1d97dcb3e706a0b9af?apiKey=f4720969da584a5080659c2fbf41bfab&"
                        alt="Next"
                        class="tips-pagination-icon"
                    />
                </button>
            </div>
        </div>
    </section>
</template>
