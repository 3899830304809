<script>
import VueSlickCarousel from 'vue-slick-carousel';
export default {
    name: "ShowcaseProducts",
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/showcase_default.png"
        },
        imageShowcase: {
            type: String,
            default: "build/assets/image_showcase.png"
        }
    },
    data() {
        return {
            sliderSettings: {
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dotsClass: "slick-dots custom-dots",
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        },
                    },
                ],
            },
            sliders: []
        };
    },
    beforeMount() {
        this.sliders = JSON.parse(this.data);
    }
};
</script>
<template>
    <div class="product-showcase">
        <div class="product-grid-showcase">
            <div class="product-column-showcase">
                <div class="product-card-menu">
                    <div class="product-image-container">
                        <img
                            :src="imageShowcase"
                            alt="Product showcase"
                            class="product-image-showcase"
                        />
                        <div class="product-details">
                            <h2 class="product-category">{{ sliders.title }}</h2>
                            <p class="product-description">
                                {{ sliders.description }}
                            </p>
                            <p class="product-summary">
                                {{ sliders.summary }}
                            </p>
                            <a href="" class="view-more-button">{{ sliders.button }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-column-showcase">
                <VueSlickCarousel v-bind="sliderSettings">
                    <div class="product-image-container" v-for="item in sliders.showCase" :key="item.id">
                        <div class="product-details product-detail-sub">
                            <h2 class="product-category">{{ sliders.title }}</h2>
                            <p class="product-description">
                                {{ sliders.description }}
                            </p>
                            <p class="product-summary">
                                {{ sliders.summary }}
                            </p>
                            <a href="" class="view-more-button">{{ sliders.button }}</a>
                        </div>
                        <img
                            :src="item.image.path ?? defaultImage"
                            alt="banner"
                            class="product-image-showcase"
                            loading="lazy"
                        />
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>
