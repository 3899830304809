<template>
    <section class="features-section" :style="[gridStyle, displayNone]">
        <div class="feature" v-for="(feature, index) in features" :key="index" v-show="feature.title && feature.icon">
            <div class="feature-icon">
                <img :src="feature.icon" alt="icon-feature">
            </div>
            <div class="feature-title">
                {{ feature.title }}
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Features',
    components: {

    },
    props: {
        data: [],
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            features: this.data,
        };
    },
    computed: {
        gridStyle() {
            if (this.windowWidth > 1199)
                return {
                    gridTemplateColumns: `repeat(${this.countFeatures()}, auto)`
                };
            return {};
        },

        displayNone() {
            if (this.countFeatures() === 0) {
                return {
                    display: 'none'
                };
            }
        }
    },
    methods: {
        countFeatures() {
            return this.features.filter(feature => feature.title != null && feature.icon).length;
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
};
</script>
