<script>
export default {
    props: {
        tranfer: {
            type: String,
            default: "build/assets/tranfer.svg",
        },
        register: {
            type: String,
            default: "build/assets/register.svg",
        },
        confirm: {
            type: String,
            default: "build/assets/confirm.svg",
        },
        connect: {
            type: String,
            default: "build/assets/connect.svg",
        },
        sell: {
            type: String,
            default: "build/assets/sell.svg",
        },
        cart: {
            type: String,
            default: "build/assets/cart.svg",
        },
        cart_small: {
            type: String,
            default: "build/assets/cart_small.svg",
        },
        infor: {
            type: String,
            default: "build/assets/infor.svg",
        },
        feature: {
            type: String,
            default: "build/assets/feature.svg",
        },
        delivery: {
            type: String,
            default: "build/assets/delivery.svg",
        },
        data: {
            type: String,
        },
    },
    data() {
        return {
            process: [],
        };
    },
    beforeMount() {
        this.process = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="process-section">
        <h2 class="section-title-menu">{{ process.title }}</h2>

        <div class="provider-process">
            <div class="provider-title">
                <span class="provider-title-text">{{ process.provider_title }}</span>
                <img :src="tranfer" alt="Provider process icon" class="provider-title-icon" loading="lazy" />
            </div>

            <div class="provider-step">
                <img :src="register" alt="Register information icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.provider_step_1 }}</p>
            </div>

            <div class="provider-step">
                <img :src="confirm" alt="Confirm information icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.provider_step_2 }}</p>
            </div>

            <div class="provider-step">
                <img :src="connect" alt="Exchange work process icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.provider_step_3 }}</p>
            </div>

            <div class="provider-step">
                <img :src="sell" alt="Sell products icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.provider_step_4 }}</p>
            </div>
        </div>

        <div class="provider-process">
            <div class="provider-title" style="background: #eec144">
                <span class="provider-title-text">{{ process.customer_title }}</span>
                <img :src="cart" alt="Provider process icon" class="provider-title-icon" loading="lazy" />
            </div>

            <div class="provider-step">
                <img :src="cart_small" alt="Register information icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.customer_step_1 }}</p>
            </div>

            <div class="provider-step">
                <img :src="infor" alt="Confirm information icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.customer_step_2 }}</p>
            </div>

            <div class="provider-step">
                <img :src="feature" alt="Exchange work process icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.customer_step_3 }}</p>
            </div>

            <div class="provider-step">
                <img :src="delivery" alt="Sell products icon" class="provider-step-icon" loading="lazy" />
                <p class="provider-step-text">{{ process.customer_step_4 }}</p>
            </div>
        </div>
    </section>
</template>
