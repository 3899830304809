<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
    components: {
        VueSlickCarousel,
    },
    props: {
        data: [],
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: 5,
                slidesToScroll: 1,
                centerMode: true,
                dots: false,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3
                        },
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1
                        },
                    },
                ],
            },
            windowWidth: window.innerWidth,
            sectionData: this.data,
        };
    },
    computed: {
        listBrands() {
            return this.data.listBrands.filter((brand) => brand.logo.path);
        },
        countBrands() {
            return this.listBrands.length;
        },
        showSlides() {
            const { windowWidth, countBrands } = this;

            if (windowWidth < 576) {
                return countBrands >= 2;
            }

            if (windowWidth < 991) {
                return countBrands >= 4;
            }

            return countBrands >= 6;
        },
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
};
</script>
<template>
    <section class="customer-section" v-if="countBrands > 0">
        <div class="customer-title">
            <h2 class="section-title-menu">
                {{ sectionData.title }}
            </h2>
            <p class="sub-title-menu">
                {{ sectionData.subtitle }}
            </p>
        </div>
        <div class="brand-logos logos-flex" v-if="!showSlides">
            <div class="logo" v-for="brand in listBrands" :key="brand.id">
                <img :src="brand.logo.path" :alt="brand.logo.filename" />
            </div>
        </div>
        <div class="brand-logos" v-else>
            <VueSlickCarousel ref="slick" v-bind="slickOptions" >
                <div class="logo" v-for="brand in listBrands" :key="brand.id">
                    <img :src="brand.logo.path" :alt="brand.logo.filename" />
                </div>
            </VueSlickCarousel>
        </div>
    </section>
</template>
