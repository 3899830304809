<template>
    <div class="grid-view-products-item">
        <a :href="productLink" :key="product.id">
            <ProductCard :product="product" />
        </a>
    </div>
</template>

<script>
const ProductCard = () => import("./../../ProductCard.vue");

export default {
    components: { ProductCard },

    props: ["product"],

    computed: {
        productLink() {
            return `san-pham/${encodeURIComponent(this.product.slug)}`;
        }
    }
};
</script>
