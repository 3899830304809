<template>
    <div class="slide-banner" :style="displayNone">
        <div class="banner-container" v-if="countSlides == 1"
            :style="{ backgroundImage: 'url(' + slides[0].file.path + ')' }">
            <div class="banner-content">
                <h2 v-if="slides[0].caption_1">{{ slides[0].caption_1 }}</h2>
                <h2 v-if="slides[0].caption_2">{{ slides[0].caption_2 }}</h2>
                <a class="view-more-button" :href="slides[0].call_to_action_url"
                    v-if="slides[0].call_to_action_text && slides[0].call_to_action_url">
                    {{ slides[0].call_to_action_text }}
                </a>
            </div>
        </div>
        <VueSlickCarousel v-bind="sliderSettings" v-if="countSlides > 1">
            <div class="banner-container" v-for="(item, index) in slides" :key="index" :style="{
                    backgroundImage: 'url(' + item.file.path + ')'
                }">
                <div class="banner-content">
                    <h2 v-if="item.caption_1">{{ item.caption_1 }}</h2>
                    <h2 v-if="item.caption_2">{{ item.caption_2 }}</h2>
                    <a class="view-more-button" :href="item.call_to_action_url"
                        v-if="item.call_to_action_text  && item.call_to_action_url">
                        {{ item.call_to_action_text }}
                    </a>
                </div>
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    name: "SlideBanner",
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String,
    },
    data() {
        return {
            sliderSettings: {
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dotsClass: "slick-dots custom-dots",
                autoplay: true,
                autoplaySpeed: 3000,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        },
                    },
                ],
            },
            slideBanner: JSON.parse(this.data),
        };
    },
    computed: {
        countSlides() {
            return this.slideBanner.slides.filter((item) => item.file.path).length;
        },
        displayNone() {
            if (this.countSlides === 0) {
                return {
                    display: 'none'
                };
            }
        },
        slides() {
            return this.slideBanner.slides.filter((item) => item.file.path);
        },
    },
    beforeMount() {
        this.sliderSettings = {
            ...this.sliderSettings,
            autoplay: Boolean(this.slideBanner.autoplay),
            autoplaySpeed: this.slideBanner.autoplay_speed,
            dots: Boolean(this.slideBanner.dots),
        };
    }
}
</script>
